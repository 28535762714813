<template>
  <div>
    <div class="table_sty">
      <div class="froms">
        <p class="describe">筛选</p>
        <div class="segmentation"></div>
        <div class="from_submit">
          <el-form :model="formLabelAlign" class="from">
            <el-form-item label="名称：" label-width="60px" style="width:310px">
              <el-input placeholder="请输入姓名" size="mini" class="form-inp" v-model="formLabelAlign.username"></el-input>
            </el-form-item>
            <el-form-item label="身份证号：" label-width="90px" style="width:340px">
              <el-input placeholder="请输入身份证号" size="mini" class="form-inp" v-model="formLabelAlign.idcard"></el-input>
            </el-form-item>
            <el-form-item label="状态：" label-width="60px">
              <el-select v-model="formLabelAlign.status" placeholder="请选择">
                <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="search_button">
            <div>
              <el-button @click="way_tableData(true)" type="primary" icon="el-icon-search">查询</el-button>
              <el-button @click="on_reset" icon="el-icon-refresh-left">重置</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="tab_sty">
        <div class="tab_top">
          <p>
            <span class="span1">一共{{ total }}个记录</span>
            <span class="span2">已选择 {{ more_List.length }} 个记录</span>
            <i @click="way_tableData" class="span_i el-icon-refresh">数据更新</i>
          </p>
        </div>
        <div>
          <el-table highlight-current-row @current-change="way_singleselect" @selection-change="way_moreselect"
            :data="table_List" :header-cell-style="{ background: '#f8f8f9' }" style="flex: 1">
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="username" label="学生姓名"></el-table-column>
            <el-table-column prop="gender" label="性别"></el-table-column>
            <el-table-column prop="nation" label="族别"></el-table-column>
            <el-table-column prop="birth" label="出生年月"></el-table-column>
            <el-table-column prop="politics" label="政治面貌"></el-table-column>
            <el-table-column prop="birthplace" label="出生所在地"></el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-link class="list_sty" type="primary" @click="on_particulars(scope.row)">查看更多</el-link>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="paging">
          <el-pagination @current-change="way_page" :page-size="page_size" layout="prev, pager, next, jumper"
            :total="total" :current-page="page">
          </el-pagination>
        </div>
      </div>
      <el-dialog title="详情" :visible.sync="dialogVisible" width="50%" :before-close="handleClose">
        <div class="boxs">
          <div class="item">
            <div class="lable">学生姓名：</div>
            <div class="cont">{{ details.username }}</div>
          </div>
          <div class="item">
            <div class="lable">免冠照：</div>
            <div class="cont">
              <el-image style="width: 100px; height: 100px; object-fit: cover" :src="details.pic"></el-image>
            </div>
          </div>
          <div class="item">
            <div class="lable">性别：</div>
            <div class="cont">{{ details.gender }}</div>
          </div>
          <div class="item">
            <div class="lable">族别：</div>
            <div class="cont">{{ details.nation }}</div>
          </div>
          <div class="item">
            <div class="lable">出生年月：</div>
            <div class="cont">{{ details.birth }}</div>
          </div>
          <div class="item">
            <div class="lable">政治面貌：</div>
            <div class="cont">{{ details.politics }}</div>
          </div>
          <div class="item">
            <div class="lable">出生所在地：</div>
            <div class="cont">{{ details.birthplace }}</div>
          </div>
        </div>
        <h3 class="title">户籍所在地及落户时间</h3>
        <div class="boxs">
          <div class="item">
            <div class="lable">户籍所在地派出所：</div>
            <div class="cont">{{ details.precinct }}</div>
          </div>
          <div class="item">
            <div class="lable">落户时间：</div>
            <div class="cont">{{ details.settle_time }}</div>
          </div>
          <div class="item">
            <div class="lable">身份证号：</div>
            <div class="cont">{{ details.idcard }}</div>
          </div>
          <div class="item">
            <div class="lable">考生与现户主关系：</div>
            <div class="cont">{{ details.bond }}</div>
          </div>
          <div class="item">
            <div class="lable">户主姓名：</div>
            <div class="cont">{{ details.householder }}</div>
          </div>
          <div class="item">
            <div class="lable">户籍城乡地域属性：</div>
            <div class="cont">{{ details.domicile }}</div>
          </div>
          <div class="item">
            <div class="lable">户号：</div>
            <div class="cont">{{ details.housenum }}</div>
          </div>
          <div class="item">
            <div class="lable">户籍登记机关：</div>
            <div class="cont">{{ details.signorgan }}</div>
          </div>
        </div>
        <h3 class="title">考生父母（或法定监护人）基本信息</h3>
        <el-table border :data="gridData">
          <el-table-column property="relation" label="监护人关系" width="150"></el-table-column>
          <el-table-column property="name" label="监护人姓名" width="200"></el-table-column>
          <el-table-column property="idcrad" label="身份证号"></el-table-column>
          <el-table-column property="census" label="户籍所在地派出所" width="150"></el-table-column>
          <el-table-column property="settle_time" label="落户时间" width="150"></el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogVisible = false">关闭</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import table_minin from "../../assets/minin/table_minin";
import { householdList } from "../../assets/request/api";
export default {
  mixins: [table_minin],
  data() {
    return {
      dialogVisible: false,
      options: [
        {
          id: 0,
          name: "保存"
        },
        {
          id: 1,
          name: "提交"
        }
      ],
      formLabelAlign: {
        username: "",
        status: "",
        idcard: ""
      },
      details: {},
      gridData: []
    };
  },
  created() {
    this.url = householdList;
    this.fromData = this.formLabelAlign;
    this.xl_attribute = "type_list";
    this.way_tableData();
  },
  methods: {
    way_extend(data) { },
    //详情弹框
    on_particulars(row) {
      this.dialogVisible = true;
      this.details = row;
      this.gridData = row.guardian;
    },
    handleClose() {
      this.dialogVisible = false;
    }
  }
};
</script>
<style lang="less" scoped>
.describe {
  display: inline-block;
  position: relative;
  padding: 4px;
  font-size: 16px;
  color: #4f7afd;
  font-weight: bold;
  padding-bottom: 10px;
}

.describe:before {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 50%;
  height: 4px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #4f7afd;
}

.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}

.form-inp {
  width: 200px;
}

.paging {
  text-align: center;
  padding: 10px;
}

.tab_top {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;

  button {
    margin-right: 10px;
  }

  p {
    display: flex;
    align-items: center;
  }
}

.span1 {
  font-size: 14px;
  color: #000000;
}

.span2 {
  font-size: 14px;
  margin-left: 10px;
  color: #797979;
}

.span_i {
  font-size: 14px;
  color: #797979;
  margin-left: 10px;
  cursor: pointer;
}

.el-divider__text {
  color: #0080ff;
  font-weight: bold;
}

.segmentation {
  height: 1px;
  background-color: #f4f4f4;
  margin-bottom: 20px;
}

.list_sty {
  margin-right: 10px;
}

.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}

.title {
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0 20px;
  color: #333;
}

.boxs {
  display: flex;
  flex-wrap: wrap;

  .item {
    width: 50%;
    display: flex;
    margin-bottom: 20px;

    .lable {
      white-space: nowrap;
    }
  }
}

::v-deep .el-dialog {
  overflow-y: scroll;
  max-height: 550px;
  min-width: none;

  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.2);
  }

  .block {
    margin-right: 10px;
  }
}
</style>
